import { Popover, PopoverContent, PopoverTrigger } from "./ui/popover";
import { User } from "@phosphor-icons/react/dist/ssr";
import Link from "next/link";
import { cn } from "@/lib/utils";
import { signOut } from "next-auth/react";
import { Button } from "./ui/button";
import { useSession } from "next-auth/react";
import { useEffect, useRef, useState } from "react";

const NavbarProfile = () => {
  const { data: session } = useSession();
  const [alertOpen, setAlertOpen] = useState<boolean>(false);
  const wrapperRef = useRef<HTMLDivElement>(null);

  function handleOnClick() {
    setAlertOpen(!alertOpen);
  }

  const handleSignOutClick = () => {
    fetch("api/db-session/remove", { method: "POST" });
    signOut({ callbackUrl: "/login?logout=true", redirect: true });
  };

  const handleMouseEnter = () => {
    setAlertOpen(true);
  };

  const handleMouseLeave = () => {
    setAlertOpen(false);
  };

  const handleHideDropdown = (event: KeyboardEvent) => {
    if (event.key === "Escape") {
      setAlertOpen(false);
    }
  };
  const handleClickOutside = (event: Event) => {
    if (
      wrapperRef.current &&
      !wrapperRef.current.contains(event.target as Node)
    ) {
      setAlertOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("keydown", handleHideDropdown, true);
    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("keydown", handleHideDropdown, true);
      document.removeEventListener("click", handleClickOutside, true);
    };
  });

  return (
    <Popover open={alertOpen}>
      <PopoverTrigger
        //  onClickCapture={toggleAlert}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        className={cn(
          "navbar-popover flex h-full w-16 items-center justify-center bg-white text-black hover:bg-primary hover:text-white",
        )}
      >
        <User width={32} height={32} />
        <span className="sr-only">Kullanıcı Profili</span>
      </PopoverTrigger>
      <PopoverContent
        className="rounded-none rounded-b-md border-none bg-primary p-0 py-3 lg:w-[170px]"
        sideOffset={0}
        align="end"
        ref={wrapperRef}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        <ul className="w-full">
          <li className="w-full">
            <Link
              onClickCapture={handleOnClick}
              href="/kullanici"
              className="block w-full px-4 py-1 text-right text-white hover:bg-[#372980]"
            >
              Profilim
            </Link>
          </li>
          <li className="w-full">
            <Link
              onClickCapture={handleOnClick}
              href="/kullanici/ayarlar"
              className="block w-full px-4 py-1 text-right text-white hover:bg-[#372980]  "
            >
              Ayarlar
            </Link>
          </li>
          {/* {session?.user.isPremium == true && ( */}
          {session && (
            <li className="w-full">
              <Link
                onClickCapture={handleOnClick}
                href="/kullanici/abonelik"
                className="block w-full px-4 py-1 text-right text-white hover:bg-[#372980]"
              >
                Premium Ayarları
              </Link>
            </li>
          )}
          <li className="w-full">
            <Link
              href="#"
              onClick={handleSignOutClick}
              className="shadow-0 block w-full p-0 px-4 py-1 text-right text-white ring-0 hover:bg-[#372980]"
            >
              Çıkış
            </Link>
          </li>
        </ul>
      </PopoverContent>
    </Popover>
  );
};

export default NavbarProfile;
