import(/* webpackMode: "eager" */ "/workspace/node_modules/@next/third-parties/dist/google/ga.js");
;
import(/* webpackMode: "eager" */ "/workspace/node_modules/@next/third-parties/dist/google/gtm.js");
;
import(/* webpackMode: "eager" */ "/workspace/node_modules/@next/third-parties/dist/ThirdPartyScriptEmbed.js");
;
import(/* webpackMode: "eager" */ "/workspace/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager" */ "/workspace/node_modules/next/dist/client/link.js");
;
import(/* webpackMode: "eager" */ "/workspace/node_modules/next/dist/client/script.js");
;
import(/* webpackMode: "eager" */ "/workspace/node_modules/next/font/google/target.css?{\"path\":\"src/app/layout.tsx\",\"import\":\"Inter\",\"arguments\":[{\"subsets\":[\"latin\"]}],\"variableName\":\"inter\"}");
;
import(/* webpackMode: "eager" */ "/workspace/src/app/globals.css");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/workspace/src/app/layout-client.tsx");
;
import(/* webpackMode: "eager" */ "/workspace/src/app/news-article.css");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/workspace/src/components/auth-provider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/workspace/src/components/Navbar.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Toaster"] */ "/workspace/src/components/ui/toaster.tsx");
